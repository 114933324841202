<template>
<v-container fluid>
  <v-data-table
    :items="archived"
    :headers="headers"
    :disable-pagination="true"
    :hide-default-footer=true
    dense
  >
    <template v-slot:[`item.img`]="{ item }">
      <image-thumbnail
        class="my-2"
        :image="item.productImages[0]"
        :maxSize="50"
        tooltip="true">
      </image-thumbnail>
      <!-- <v-img class="mx-2" max-height="50px" max-width="50px"
          :src="`${imageUrl}/100px/${item.product.images[0]}`"
        ></v-img> -->
    </template>
  </v-data-table>
</v-container>
</template>

<script>
import ProductApi from '@/api/admin/product'
import ImageThumbnail from '@/components/ImageThumbnail.vue'

export default {
  components: {
    ImageThumbnail
  },
  data () {
    return {
      archived: [],
      headers: [
        { text: '', value: 'img', width: '100px' },
        { text: 'Artist', value: 'artist' },
        { text: 'Title', value: 'title' }
      ]
    }
  },
  async created () {
    ProductApi.loadArchivedProducts().then((results) => {
      this.archived = results.data
      this.loadingData = false
    }).catch(
      err => {
        console.log('err', err)
        this.loadingData = false
      }
    )
  },
  mounted: function () {
  },
  computed: {
  },
  watch: {},
  methods: {
  }
}
</script>
